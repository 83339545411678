export default [
  {
    index: 0,
    key: "country",
    labelKey: "country",
    color: "#FF0000",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-paraguay:country"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Dirección General de Estadística, Encuestas y Censos.' },
      { labelKey: 'year', label: 'Ano', value: '2012' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/paraguay/collection_1/download/layers/nivel_politico_1.zip'
  },
  {
    index: 1,
    key: "department",
    labelKey: "department",
    color: "#FFA500",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-paraguay:department"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Dirección General de Estadística, Encuestas y Censos.' },
      { labelKey: 'year', label: 'Ano', value: '2012' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/paraguay/collection_1/download/layers/nivel_politico_2.zip'
  },
  {
    index: 2,
    key: "district",
    labelKey: "district",
    color: "#FF00FF",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-paraguay:district"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Instituto Nacional de Estadísticas' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/paraguay/collection_1/download/layers/nivel_politico_3.zip'
  },
  {
    index: 3,
    key: "protected_areas",
    labelKey: "protected_areas",
    color: "#00FF00",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-paraguay:protected_areas"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Ministerio del Ambiente y Desarrollo Sostenible (MADES)' },
      { labelKey: 'year', label: 'Ano', value: '2022' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/paraguay/collection_1/download/layers/areas_silvestres_protegidas.zip'
  },
  {
    index: 4,
    key: "ramsar_site",
    labelKey: "ramsar_site",
    color: "#FFFF00",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-paraguay:ramsar_site"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'WWF' },
      { labelKey: 'year', label: 'Ano', value: '2013' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/paraguay/collection_1/download/layers/sitios_ramsar.zip'
  },
  {
    index: 5,
    key: "ecorregions_seam",
    labelKey: "ecorregions_seam",
    color: "#800080",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-paraguay:ecorregions_seam"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Secretaria del ambiente' },
      { labelKey: 'year', label: 'Ano', value: '2013' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/paraguay/collection_1/download/layers/ecorregiones_seam.zip'
  },
  {
    index: 6,
    key: "ecorregions_dinerstein",
    labelKey: "ecorregions_dinerstein",
    color: "#FFC0CB",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-paraguay:ecorregions_dinerstein"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Adaptada por UdeLAR en base a datos Oficiales' },
      { labelKey: 'year', label: 'Ano', value: '1995' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/paraguay/collection_1/download/layers/ecorregiones_dinerstein.zip'
  },
  {
    index: 7,
    key: "indigenous_communities",
    labelKey: "indigenous_communities",
    color: "#0000FF",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-paraguay:indigenous_communities"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Federación por la autodenominacion de los Pueblos indígenas' },
      { labelKey: 'year', label: 'Ano', value: '2017' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/paraguay/collection_1/download/layers/comunidades_indigenas.zip'
  },
  {
    index: 8,
    key: "political_regions",
    labelKey: "political_regions",
    color: "#40E0D0",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-paraguay:political_regions"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Dirección General de Estadística, Encuestas y Censos.' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/paraguay/collection_1/download/layers/regiones.zip'
  },
  {
    index: 9,
    key: "biosphere_reserve",
    labelKey: "biosphere_reserve",
    color: "#00FF00",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-paraguay:biosphere_reserve"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Ministerio del Ambiente y Desarrollo Sostenible (MADES)' },
      { labelKey: 'year', label: 'Ano', value: '2022' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/paraguay/collection_1/download/layers/reserva_de_la_biosfera.zip'
  }
];
